import { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useUpdateLessonGroupQuizResultMutation } from "redux/api/Groups/groupsApi";

const useStyles = makeStyles({
  tableBodyCell: {
    width: "170px",
    padding: "20px 12px",
    borderRight: "2px solid #42446033",
    position: "relative",
    "&:last-child": {
      border: "unset",
    },
  },
  input: {
    width: "100%",
    border: "1px solid transparent",
    background: "unset",

    "&:hover": {
      border: "1px solid black",
    },
  },
});

export default function ExamTableCell({ value = null, id, showSnackbar }) {
  const [initialValue, setInitialValue] = useState(value ?? "");
  const [state, setState] = useState(initialValue);

  const [
    updateResult,
    { isLoading, isSuccess, error },
  ] = useUpdateLessonGroupQuizResultMutation();

  const handleChange = useCallback(
    (e) => {
      if (!isLoading) {
        setState(e.target.value ? e.target.value : null);
      }
    },
    [isLoading]
  );

  const classes = useStyles();

  useEffect(() => {
    if (error) {
      setState(initialValue);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setInitialValue(state);
      showSnackbar("Field successfully updated!");
    }
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateResult({
      answeredQuestionCount: state,
      id,
    });
  };

  return (
    <td className={classes.tableBodyCell}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={state || ""}
          onChange={handleChange}
          onBlur={handleSubmit}
          className={classes.input}
        />
      </form>
    </td>
  );
}
