import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setStep } from "../../../../../redux/features/Books/booksSlice";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useGetBookOrdersQuery } from "../../../../../redux/api/Books/booksApi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Delivery from "./modals/Delivery";
import Filter from "./modals/Filter";
import Pagination from "@material-ui/lab/Pagination";
import { format } from "date-fns";

const useStyles = makeStyles({
  divider: {
    height: "1px",
    width: "100%",
  },
  wrapper: {
    height: " calc(100% - 65px)",
    paddingTop: "12px",
  },
  circularProgress: {
    color: "#34A9FC",
  },
  card: {
    background: "#FFFFFF",
    boxShadow: "0 2px 18px rgba(62, 74, 97, 0.12)",
    borderRadius: "12px",
    padding: "20px 24px",
    display: "flex",
  },

  cardCol: {
    marginRight: "24px",
  },
  cardImg: {
    width: "182px",
    height: "146px",
    background: "gray",
    borderRadius: "16px",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  cardTitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "27px",
    color: "#050A46",
  },
  cardDescription: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "27px",
    color: "#050A46",
  },
  cardCaption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardOperations: {
    width: "200px",
    display: "flex",
    justifyContent: "center",
  },
  label: {
    width: "100px",
  },
});

export default function Order({ renderMethods, bookName, bookId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState({
    id: "",
    delivered: false,
  });
  const [orderPage, setOrderPage] = useState(1);

  const [state, setState] = useState({
    skip: 0,
    take: 10,
    delivered: "notDelivered",
    orderBy: true,
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    email: "",
  });

  const { data, isLoading } = useGetBookOrdersQuery({
    ...state,
    bookId,
  });

  function handleChange() {
    setShowFilter((prevState) => !prevState);
  }

  function handleChangePage(e, page) {
    setOrderPage(page);
    const tempSkip = (page - 1) * state.take;
    setState((prevState) => ({
      ...prevState,
      skip: tempSkip,
    }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <button
          onClick={() => {
            dispatch(setStep(renderMethods.books));
          }}
          className="button-primary mr-2"
        >
          <ArrowBackIcon />
        </button>
        <h1 className="display-6 mb-0">Orders of "{bookName}"</h1>
        <div className="ml-auto">
          <FormControlLabel
            control={
              <Switch
                checked={showFilter}
                onChange={handleChange}
                color="secondary"
              />
            }
            label={showFilter ? "Hide filter" : "Show filter"}
            className="ml-auto mb-0"
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={`col-12 ${classes.wrapper}`}>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#34A9FC" }} />
          </div>
        ) : !isLoading && !data?.data?.length ? (
          <AlertComponent message="There is no order." variant="info" />
        ) : (
          data?.data.map((item, index) => (
            <div key={index} className={classes.card}>
              <div className={classes.cardCol}>
                <div className={classes.cardImg}>
                  <img
                    src={
                      item.profileImage
                        ? item.profileImage
                        : "/media/image/profilePic.png"
                    }
                    alt="student"
                  />
                </div>
                <div className={classes.cardOperations}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.delivered}
                        onChange={() => {
                          setShowDelivery((prevState) => !prevState);
                          setDeliveryStatus({
                            id: item.id,
                            delivered: !item.delivered,
                          });
                        }}
                        color="secondary"
                      />
                    }
                    label={
                      item.delivered
                        ? "Set as not delivered"
                        : "Set as delivered"
                    }
                  />
                </div>
              </div>
              <div className={classes.cardCol}>
                <p className={classes.cardTitle}>
                  Full name:{" "}
                  <span className={classes.cardDescription}>
                    {item.firstName} {item.lastName}
                  </span>
                </p>
                <p className={classes.cardTitle}>
                  Phone:{" "}
                  <span className={classes.cardDescription}>{item.phone}</span>
                </p>
                <p className={classes.cardTitle}>
                  Email:{" "}
                  <span className={classes.cardDescription}>{item.email}</span>
                </p>
                <p className={classes.cardTitle}>
                  Address:{" "}
                  <span className={classes.cardDescription}>
                    {item.address}
                  </span>
                </p>
              </div>
              <div className={classes.cardCol}>
                <p className={classes.cardTitle}>
                  Quantity:{" "}
                  <span className={classes.cardDescription}>
                    {item.quantity}
                  </span>
                </p>
                <p className={classes.cardTitle}>
                  Price:{" "}
                  <span className={classes.cardDescription}>
                    {item.price} $
                  </span>
                </p>
                <p className={classes.cardTitle}>
                  Discount:{" "}
                  <span className={classes.cardDescription}>
                    {item.discount ? `${item.discount} $` : "0 $"}
                  </span>
                </p>
                <p className={classes.cardTitle}>
                  Order date:{" "}
                  <span className={classes.cardDescription}>
                    {format(new Date(item.created), "dd.MM.yyyy")}
                  </span>
                </p>
              </div>
            </div>
          ))
        )}
        {data?.count > state.take && (
          <div className="py-2">
            <Pagination
              onChange={(e, page) => handleChangePage(e, page)}
              count={Math.ceil(data?.count / state.take)}
              page={orderPage}
            />
          </div>
        )}
        {showDelivery && (
          <Delivery
            show={showDelivery}
            onHide={setShowDelivery}
            deliveryStatus={deliveryStatus}
          />
        )}
        {showFilter && (
          <Filter
            show={showFilter}
            onHide={setShowFilter}
            state={state}
            setState={setState}
          />
        )}
      </div>
    </>
  );
}
