import { createApi } from "@reduxjs/toolkit/query/react";
// import FetchBase from "../FetchBase";
import { APIBaseQuery } from "../AxiosBase";
import { setCourses } from "../../features/Courses/coursesSlice";
import { v4 as uuid } from "uuid";

export const coursesApi = createApi({
  reducerPath: "coursesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["courses"],
  endpoints: (builder) => ({
    getCourses: builder.query({
      query(filter) {
        const {
          skip,
          take,
          sortField,
          orderBy,
          searchTerm,
          SearchTerm,
          fromDate,
          toDate,
          title,
          rate,
        } = filter;
        return {
          url: `courses?skip=${skip}&take=${take}&sortField=updated&order=false`,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["courses"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCourses(data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getCoursesById: builder.query({
      query(id) {
        return {
          url: `courses/${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    createCourses: builder.mutation({
      query(form) {
        return {
          url: "courses",
          method: "POST",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: form,
        };
      },
      invalidatesTags: ["courses"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      },
    }),
    updateCourses: builder.mutation({
      query(form) {
        return {
          url: "courses",
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid(),
          // },
          data: form,
        };
      },
      invalidatesTags: ["courses"],
      transformResponse: (result) => result,
    }),
    removeCourses: builder.mutation({
      query(id) {
        return {
          url: `courses/${id}`,
          method: "DELETE",
          // headers: {
          //     "x-requestid": uuid(),
          // },
        };
      },
      keepUnusedDataFor: 1,
      invalidatesTags: ["courses"],
    }),
    changeCourseStatus: builder.mutation({
      query(data) {
        const { id, status, url } = data;
        return {
          url: `${url}/${id}/status`,
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: { status },
        };
      },
      keepUnusedDataFor: 1,
    }),
    getCategory: builder.query({
      query() {
        return {
          url: "categories/list",
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getSpecifications: builder.query({
      query() {
        return {
          url: "specifications/list",
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getContents: builder.query({
      query(filter = {}) {
        const { id, skip, take } = filter;
        return {
          url: `contents?itemId=${id}&skip=${skip}&take=${take}&orderBy=true&sortField=order`,
        };
      },
    }),
    getContentsById: builder.query({
      query(id) {
        return {
          url: `contents/${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    createContent: builder.mutation({
      query(body) {
        return {
          url: "contents",
          method: "POST",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: body,
        };
      },
      keepUnusedDataFor: 1,
    }),
    updateContent: builder.mutation({
      query(body) {
        return {
          url: `contents`,
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: body,
        };
      },
      keepUnusedDataFor: 1,
    }),
    removeContent: builder.mutation({
      query(id) {
        return {
          url: `contents/${id}`,
          method: "DELETE",
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      keepUnusedDataFor: 1,
    }),
    changeContentStatus: builder.mutation({
      query(data) {
        const { id, status } = data;
        return {
          url: `contents/${id}/status`,
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: { status },
        };
      },
      keepUnusedDataFor: 1,
    }),
    getVideos: builder.query({
      query(filter) {
        const { skip, take, contentId, slug, title, description } = filter;
        return {
          url: `videos?skip=${skip}&take=${take}&contentId=${contentId}${
            slug ? `&slug=${slug}` : ""
          }${title ? `&title=${title}` : ""}${
            description ? `&description=${description}` : ""
          }&sortField=order&orderBy=true`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getVideoById: builder.query({
      query(id) {
        return {
          url: `videos/${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    removeVideo: builder.mutation({
      query(id) {
        return {
          url: `videos/${id}`,
          method: "DELETE",
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      keepUnusedDataFor: 1,
    }),
    changeVideoStatus: builder.mutation({
      query(data) {
        const { id, status } = data;
        return {
          url: `videos/${id}/status`,
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: { status },
        };
      },
      keepUnusedDataFor: 1,
    }),
    getQuestions: builder.query({
      query(filter = {}) {
        const { skip, take, videoId } = filter;
        return {
          url: `questions?videoId=${videoId}&skip=${skip}${
            take ? `&take=${take}` : ""
          }&sortField=order&orderBy=true`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getQuestionById: builder.query({
      query(questionId) {
        return {
          url: `questions/${questionId}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getExplanationVideo: builder.query({
      query(id) {
        return {
          url: `questions/${id}/question-video`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    createQuestion: builder.mutation({
      query() {
        return {
          url: `questions`,
          method: "POST",
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      keepUnusedDataFor: 1,
    }),
    removeQuestion: builder.mutation({
      query(id) {
        return {
          url: `questions/${id}`,
          method: "DELETE",
          // headers: {
          //     "x-requestid": uuid()
          // },
        };
      },
      keepUnusedDataFor: 1,
    }),
    changeQuestionStatus: builder.mutation({
      query(data) {
        const { id, status } = data;
        return {
          url: `questions/${id}/status`,
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: { status },
        };
      },
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useGetCoursesByIdQuery,
  useCreateCoursesMutation,
  useUpdateCoursesMutation,
  useRemoveCoursesMutation,
  useChangeCourseStatusMutation,
  useGetCategoryQuery,
  useGetSpecificationsQuery,
  useGetContentsByIdQuery,
  useGetContentsQuery,
  useCreateContentMutation,
  useUpdateContentMutation,
  useRemoveContentMutation,
  useChangeContentStatusMutation,
  useGetVideosQuery,
  useGetVideoByIdQuery,
  useGetExplanationVideoQuery,
  useRemoveVideoMutation,
  useChangeVideoStatusMutation,
  useGetQuestionsQuery,
  useGetQuestionByIdQuery,
  useCreateQuestionMutation,
  useRemoveQuestionMutation,
  useChangeQuestionStatusMutation,
} = coursesApi;
