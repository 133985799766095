import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, Divider, FormControlLabel } from "@material-ui/core";
import Table from "./components/Table";
import { useGetBookOrdersQuery } from "redux/api/Books/booksApi";
import Filter from "../Books/components/Order/modals/Filter";
import { setUndeliveredBook } from "redux/features/Books/booksSlice";

export default function BookOrders() {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [state, setState] = useState({
    skip: 0,
    take: 10,
    delivered: "notDelivered",
    orderBy: true,
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    email: "",
    ItemId: null,
    fromDate: "",
    toDate: "",
  });

  const { data, isLoading } = useGetBookOrdersQuery({
    ...state,
  });

  useEffect(() => {
    if (data && state.delivered === "notDelivered") {
      dispatch(setUndeliveredBook(data?.count >= 1));
    }
  }, [data, state.delivered]);

  return (
    <div className="row bg-white rounded h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Book orders</h1>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={showFilter}
              onChange={() => setShowFilter(true)}
            />
          }
          label="Show filter"
          className="ml-auto mb-0"
        />
      </div>
      <Divider className="w-100" />
      <Table
        data={data}
        isLoading={isLoading}
        state={state}
        setState={setState}
      />
      {showFilter && (
        <Filter
          show={showFilter}
          onHide={setShowFilter}
          state={state}
          setState={setState}
        />
      )}
    </div>
  );
}
