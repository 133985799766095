import React, {useEffect, useRef, useMemo, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useCreateQuestionMutation} from "../../../../redux/api/Courses/coursesApi";
import ReactQuill, {Quill} from 'react-quill';
import BlotFormatter from "quill-blot-formatter"
import 'react-quill/dist/quill.snow.css';
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Reader from "../components/Reader";
import PlusIcon from "@material-ui/icons/Add"
import {Tooltip, Checkbox} from "@material-ui/core";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import LinearProgress from "../components/LinearProgress";
import axios from "axios";
import {v4 as uuid} from "uuid";
import {useSelector} from "react-redux";

export default function CreateQuestion({show, onHide, totalCount, videoId, refetch}) {
    // TODO: find the best practise for registering BlotFormatter without getting an error on console.

    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.debug('error')

    const formRef = useRef();
    const {token} = useSelector(({user}) => ({token: user.token}));


    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [videoSrc, setVideoSrc] = useState("");
    const [percent, setPercent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [questionVariants, setQuestionVariants] = useState([
        {
            variant: "",
            order: 1,
            isAnswer: true
        }
    ]);
    const [openQuestionVariants, setOpenQuestionVariants] = useState([
        {
            variant: "",
            order: 1,
            isAnswer: true
        }
    ])

    const addVariant = () => {
        if (isOpen) {
            let index = openQuestionVariants.length - 1;
            let order = index === -1 ? 1 : openQuestionVariants[index].order + 1
            let tempState = [...openQuestionVariants, {
                variant: "",
                order,
                isAnswer: true
            }];
            setOpenQuestionVariants(tempState)
        } else {
            let index = questionVariants.length - 1;
            let order = index === -1 ? 1 : questionVariants[index].order + 1
            let tempState = [...questionVariants, {
                variant: "",
                order,
                isAnswer: false
            }];
            setQuestionVariants(tempState)
        }
    };

    const removeVariant = (index) => {
        if (isOpen) {
            let tempState = openQuestionVariants.filter(item => item.order !== index)
            setOpenQuestionVariants(tempState);
        } else {
            let tempState = questionVariants.filter(item => item.order !== index)
            setQuestionVariants(tempState);
        }
    };

    const chooseAnswer = (ObjIndex) => {
        let correctAnswerIndex = questionVariants.findIndex(item => item.isAnswer === true);

        if (ObjIndex !== correctAnswerIndex) {
            let tempState = questionVariants.map((variant, idx) => {
                if (idx === ObjIndex) {
                    return {
                        ...variant, isAnswer: true
                    }
                } else {
                    return {
                        ...variant, isAnswer: false
                    }
                }
            });

            setQuestionVariants(tempState)
        }
    }

    const handleChangeVariant = (content, delta, source, editor, index) => {
        let tempState = [...questionVariants];
        tempState[index].variant = content;
        setQuestionVariants(tempState);
        window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
    };

    const handleChangeOpenVariant = (e, index) => {
        let tempState = [...openQuestionVariants];
        tempState[index].variant = e.target.value;
        setOpenQuestionVariants(tempState)
    }


    const handleTitle = (content, delta, source, editor) => {
        setTitle(content)
        window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
    };

    const handleVideo = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(e.target.value);
        setFileName(file.name);
        reader.onloadend = () => {
            setVideoSrc(reader.result)
        };

        reader.readAsDataURL(file);
    };

    const handleChange = (e) => {
        setIsOpen(val => !val)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let order = totalCount + 1
        const asset = new FormData(formRef.current);
        asset.append("title", title);
        asset.append("videoId", videoId);
        asset.append("isOpen", String(isOpen));
        asset.append("order", order);
        if (isOpen) {
            openQuestionVariants.forEach((variant, index) => {
                asset.append(`questionVariants[${index}].variant`, variant.variant);
                asset.append(`questionVariants[${index}].order`, variant.order);
                asset.append(`questionVariants[${index}].isAnswer`, variant.isAnswer);
            })
        } else {
            questionVariants.forEach((variant, index) => {
                asset.append(`questionVariants[${index}].variant`, variant.variant);
                asset.append(`questionVariants[${index}].order`, variant.order);
                asset.append(`questionVariants[${index}].isAnswer`, variant.isAnswer);
            })
        }
        console.log(asset);
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}questions`, asset, {
            headers: {
                // "x-requestid": uuid(),
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress: ({loaded, total}) => {
                const tempPercent = Math.round((loaded / total) * 100)
                if (percent < tempPercent) {
                    setPercent(tempPercent)
                }
            }
        }).then(() => {
            setIsLoading(false);
            setPercent(0);
            refetch();
            onHide(false);
        })
            .catch(() => {
                setIsLoading(false);
                setPercent(0)
            })
    };

    const editorRef = useRef(null);

    const imageHandlers = () => {
        console.log("image uploaded")
    };

    const modules = {
        toolbar: {
            container: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['bold', 'italic', 'underline'],
                [{'list': []}],
                [{'align': []}],
                ['clean'],
                [{'color': []}],
                ['image'],
            ],
        },
        blotFormatter: {
        }
    }

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="create">Create a question</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit} ref={formRef}>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel htmlFor="title">Title input</InputLabel>
                                    <ReactQuill
                                        modules={modules}
                                        style={{width: "100%", height: "100%"}}
                                        theme="snow"
                                        name="title"
                                        value={title}
                                        onChange={(content, delta, source, editor) => handleTitle(content, delta, source, editor)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel htmlFor="title">Title output</InputLabel>

                                    <Reader value={title}/>

                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isOpen}
                                            onChange={handleChange}
                                            color="secondary"
                                        />
                                    }
                                    label={isOpen ? "Question is open" : "Question is not open"}
                                    className="ml-auto mb-0"
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                {isOpen
                                    ? openQuestionVariants.map((variant, index) => (
                                        <div key={index} className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <InputLabel htmlFor="variant">Variant {variant.order} input</InputLabel>
                                            <div className="w-100"><TextField
                                                value={openQuestionVariants[index].variant}
                                                onChange={(e) => handleChangeOpenVariant(e, index)}/>
                                            </div>
                                            <Button onClick={() => removeVariant(variant.order)} variant="danger"
                                                    className="mt-2">Remove</Button>
                                        </div>
                                    ))
                                    : questionVariants.map((variant, index) => (
                                        <div key={index} className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <InputLabel htmlFor="title">Variant {variant.order} input</InputLabel>
                                            <ReactQuill
                                                modules={modules}
                                                style={{width: "100%", height: "100%"}}
                                                value={questionVariants[index].variant}
                                                onChange={(content, delta, source, editor) => handleChangeVariant(content, delta, source, editor, index)}
                                            />
                                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                <InputLabel htmlFor="title">Variant {variant.order} output</InputLabel>
                                                <div className="mathjax-container w-100"
                                                     dangerouslySetInnerHTML={{__html: questionVariants[index].variant}}></div>
                                            </div>
                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                <Button
                                                    onClick={() => removeVariant(variant.order)}
                                                    className="mt-2"
                                                    variant="danger">
                                                    Remove
                                                </Button>
                                                <div><FormControlLabel
                                                    control={<Checkbox checked={questionVariants[index].isAnswer}
                                                                       onChange={() => chooseAnswer(index)}/>}
                                                    label="Is correct"/></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <Tooltip title="Add a variant">
                                        <Button onClick={addVariant}><PlusIcon/></Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-column align-items-center flex-wrap">
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            accept="video/*"
                                            id="contained-button-file"
                                            type="file"
                                            name="QuestionVideo.UrlFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handleVideo}
                                            value={file}
                                            hidden
                                        />
                                        <Button2 disabled={isLoading} variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Video-camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            {file ? "File has been chosen" : "Add explanation video"}
                                        </Button2>
                                    </label>
                                    {fileName}
                                    {isLoading && <LinearProgress progress={percent}/>}
                                    {isLoading && file &&
                                        <span>Please wait until the video loads. This may take a while</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} type="submit" variant="info" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}