import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import StudentsTable from "./components/StudentsTable";
import TrialExamResult from "./modals/TrialExamResult";
import Mistakes from "./modals/Mistakes";
import Remove from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import PracticeTestResults from "./modals/PracticeTestResults";
import { useGetStudentsQuery } from "../../../redux/api/Students/studentsApi";

export default function Students() {
  const [state, setState] = useState({
    skip: 0,
    take: 10,
    order: true,
    field: "firstName",
    firstName: "",
    lastName: "",
    email: "",
    studentId: "",
    parentEmail: null,
    parentPhone: null,
    mycCoins: null,
  });
  const [showTrialExamResult, setShowTrialExamResult] = useState(false);
  const [showPracticeTestResults, setShowPracticeTestResults] = useState(false);
  const [showMistakes, setShowMistakes] = useState(false);
  const [mistakes, setMistakes] = useState([]);
  const [showRemove, setShowRemove] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  function handleChange() {
    setShowFilter((val) => !val);
  }

  const { data, isLoading } = useGetStudentsQuery({
    skip: state.skip,
    take: state.take,
    order: state.order,
    field: state.field,
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
  });
  return (
    <div className="row bg-white rounded h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Students</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Hide filter" : "Show filter"}
          className="ml-auto mb-0"
        />
      </div>
      <Divider className="w-100" />
      <StudentsTable
        state={state}
        setState={setState}
        data={data?.data}
        count={data?.count}
        isLoading={isLoading}
        setShowRemove={setShowRemove}
        setShowTrialExamResult={setShowTrialExamResult}
        setShowPracticeTestResults={setShowPracticeTestResults}
        setShowEdit={setShowEdit}
      />
      {showRemove && (
        <Remove
          studentId={state.studentId}
          show={showRemove}
          onHide={setShowRemove}
        />
      )}
      {showFilter && (
        <Filter
          show={showFilter}
          onHide={setShowFilter}
          state={state}
          setState={setState}
        />
      )}
      {showTrialExamResult && (
        <TrialExamResult
          show={showTrialExamResult}
          onHide={setShowTrialExamResult}
          studentId={state.studentId}
          setShowMistakes={setShowMistakes}
          setMistakes={setMistakes}
        />
      )}
      {showPracticeTestResults && (
        <PracticeTestResults
          show={showPracticeTestResults}
          onHide={setShowPracticeTestResults}
          studentId={state.studentId}
        />
      )}
      {showMistakes && (
        <Mistakes
          show={showMistakes}
          onHide={setShowMistakes}
          mistakes={mistakes}
        />
      )}
      {showEdit && (
        <Edit
          show={showEdit}
          onHide={setShowEdit}
          studentId={state.studentId}
          parentEmail={state.parentEmail}
          parentPhone={state.parentPhone}
          mycCoins={state.mycCoins}
        />
      )}
    </div>
  );
}
