import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useUpdateUserPasswordMutation } from "../../../../../../redux/api/Users/usersApi";
import { useSelector } from "react-redux";

import { v4 as uuid } from "uuid";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const initialValues = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

export default function ChangePassword(props) {
  const [updateUserPassword, { isSuccess }] = useUpdateUserPasswordMutation();
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const { onHide, id, show, setMessage } = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showRePassword: false,
  });
  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };
  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
      )
      .required("Confirm Password is required"),
  });

  const onChangePassword = (body) => {
    updateUserPassword(body);
  };

  const onChangeUserPassword = (password, resetForm, setSubmitting) => {
    setDisabled(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}users/update-password`,
        {
          id,
          password,
        }
        // {
        //   headers: {
        //     "x-requestid": uuid(),
        //   },
        // }
      )
      .then(() => {
        resetForm();
        setSubmitting(false);
        onHide(false);
        setDisabled(false);
        setMessage(true);
        window.setTimeout(() => {
          setMessage(false);
        }, 1000);
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;
        setIsError(message);
        setDisabled(false);
      });
  };

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onChangePassword({ ...values, id: user.id });
      // onChangeUserPassword(values.password, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      onHide(false);
    }
  }, [isSuccess]);

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="sm"
      aria-labelledby="edit"
      centered
      className="modal-center pr-0"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="changePassword">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    className="w-100"
                    type={values.showCurrentPassword ? "text" : "password"}
                    label="Current password"
                    value={values.currentPassword}
                    error={getInputClasses("currentPassword")}
                    {...formik.getFieldProps("currentPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showCurrentPassword")
                            }
                          >
                            {values.showCurrentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    className="w-100"
                    type={values.showNewPassword ? "text" : "password"}
                    label="New password"
                    value={values.newPassword}
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showNewPassword")
                            }
                          >
                            {values.showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="confirmPassword"
                    className="w-100"
                    type={values.showRePassword ? "text" : "password"}
                    label="Confirm new password"
                    value={values.confirmPassword}
                    error={getInputClasses("confirmPassword")}
                    {...formik.getFieldProps("confirmPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showRePassword")
                            }
                          >
                            {values.showRePassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.confirmPassword}
                    </div>
                  </div>
                ) : null}
                {isError && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block MuiFormLabel-root Mui-error">
                      {isError}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Save
            {disabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
