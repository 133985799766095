import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { v4 as uuid } from "uuid";

export const promoCodeApi = createApi({
  reducerPath: "promoCodeApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["promoCode"],
  endpoints: (build) => ({
    createPromoCode: build.mutation({
      query(body) {
        return {
          url: "promo-codes",
          method: "POST",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: body,
        };
      },
      invalidatesTags: ["promoCode"],
    }),
    getPromoCode: build.query({
      query(arg) {
        return {
          url: "promo-codes",
          params: {
            ...arg,
          },
          method: "GET",
        };
      },
      providesTags: ["promoCode"],
    }),
    getPromoCodeById: build.query({
      query(id) {
        return {
          url: `promo-codes/${id}`,
          method: "GET",
        };
      },
    }),
    updatePromoCode: build.mutation({
      query(body) {
        return {
          url: "promo-codes",
          method: "PUT",
          // headers: {
          //     "x-requestid": uuid()
          // },
          data: body,
        };
      },
      invalidatesTags: ["promoCode"],
    }),
    removePromoCode: build.mutation({
      query(id) {
        return {
          url: `promo-codes/${id}`,
          method: "DELETE",
          //   headers: {
          //     "x-requestid": uuid(),
          //   },
        };
      },
      invalidatesTags: ["promoCode"],
    }),
  }),
});

export const {
  useGetPromoCodeQuery,
  useGetPromoCodeByIdQuery,
  useCreatePromoCodeMutation,
  useUpdatePromoCodeMutation,
  useRemovePromoCodeMutation,
} = promoCodeApi;
