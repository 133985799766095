import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";

const headRows = [
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "tools",
    label: "Operations",
  },
];

const useStyles = makeStyles((theme) => ({
  height: {
    height: "calc(100% - 65px)",
  },
  root: {
    width: "100%",
    height: "100%",
    padding: "24px 0",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  toolbar: {
    minHeight: theme.spacing(2),
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#34a9fc",
  },
}));

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell key={row.id}>
            {row.id === "tools" ? (
              row.label
            ) : (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order ? "asc" : "desc"}
                onClick={() => {
                  return row.id !== "tools" ? onRequestSort(row.id) : null;
                }}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  state,
  setState,
  data,
  count,
  isLoading,
  setShowRemove,
  order,
  setShowTrialExamResult,
  setShowPracticeTestResults,
  setShowEdit,
}) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(+state.take);

  const onRequestSort = (fieldName) => {
    let tempOrder = fieldName === state.field && !state.order;
    setState((prevState) => ({
      ...prevState,
      field: fieldName,
      order: tempOrder,
    }));
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    const skip = newPage * rowsPerPage;
    setState((prevState) => ({
      ...prevState,
      skip,
    }));
  }

  function handleChangeRowsPerPage(event) {
    const skip = 0;
    const take = +event.target.value;
    setRowsPerPage(take);
    setPage(0);
    setState((prevState) => ({
      ...prevState,
      take,
      skip,
    }));
  }

  return (
    <div className={`col-12 ${classes.height}`}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>
            {isLoading ? (
              <div className={classes.progresRoot}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  onRequestSort={onRequestSort}
                  order={order}
                />
                <TableBody>
                  {data &&
                    data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.firstName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.phone}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Show exam results" placement="top">
                            <button
                              aria-label="showExamResults"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  studentId: row.id,
                                }));
                                setShowTrialExamResult(true);
                              }}
                            >
                              <HourglassEmptyIcon />
                            </button>
                          </Tooltip>
                          <Tooltip
                            title="Show practice tests results"
                            placement="top"
                          >
                            <button
                              aria-label="showExamResults"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  studentId: row.id,
                                }));
                                setShowPracticeTestResults(true);
                              }}
                            >
                              <AssessmentIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <button
                              aria-label="edit"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  studentId: row.id,
                                  parentEmail: row.parentEmail,
                                  parentPhone: row.parentPhone,
                                  mycCoins: row.mycCoins,
                                }));
                                setShowEdit(true);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <button
                              aria-label="Delete"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  studentId: row.id,
                                }));
                                setShowRemove(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </div>
          {count > state?.take && (
            <TablePagination
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
            />
          )}
        </Paper>
      </div>
    </div>
  );
}
