import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { useUpdateStudentMutation } from "../../../../redux/api/Students/studentsApi";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Edit({
  show,
  onHide,
  studentId,
  parentEmail,
  parentPhone,
  mycCoins,
}) {
  const [updateInfo, { isLoading }] = useUpdateStudentMutation();

  const initialValues = {
    parentEmail,
    parentPhone,
    mycCoins,
  };

  const validationSchema = Yup.object({
    parentEmail: Yup.string()
      .email("Invalid email address")
      .required("Please enter a value.")
      .nullable(),
    parentPhone: Yup.string()
      .required("Please enter a value.")
      .test("regex", "Please enter a phone number like this: 994505005050", (value) => new RegExp(/^994\d{9}$/).test(value))
      .nullable(),
    mycCoins: Yup.number().typeError("Please enter a valid number"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const body = { ...values, id: studentId };
      try {
        await updateInfo(body);
        onHide(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    name="parentEmail"
                    id="parentEmail"
                    label="Parent email"
                    placeholder="Parent email"
                    className="w-100"
                    error={getInputClasses("parentEmail")}
                    {...formik.getFieldProps("parentEmail")}
                  />
                  {formik.touched.parentEmail && formik.errors.parentEmail ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.parentEmail}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    name="parentPhone"
                    id="parentPhone"
                    label="Parent phone"
                    placeholder="Parent phone"
                    className="w-100"
                    error={getInputClasses("parentPhone")}
                    {...formik.getFieldProps("parentPhone")}
                  />
                  {formik.touched.parentPhone && formik.errors.parentPhone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.parentPhone}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    name="mycCoins"
                    id="mycCoins"
                    label="MYC Coins"
                    placeholder="MYC Coins"
                    className="w-100"
                    error={getInputClasses("mycCoins")}
                    {...formik.getFieldProps("mycCoins")}
                  />
                  {formik.touched.mycCoins && formik.errors.mycCoins ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.mycCoins}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onHide(false);
            }}
            variant="light"
          >
            Close
          </Button>
          <Button disabled={isLoading} type="submit" variant="info">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
